import React, { Component } from "react";
import { Alert } from "react-bootstrap";
import AlertService from "../services/alert.service";

class Alerts extends Component {
  constructor(props) {
    super(props);
    this.dismiss = this.dismiss.bind(this);
    this.state = {
      alerts: [],
    };
    AlertService.getAlerts()
    .then(resp => {
      console.log(resp.data);
      this.setState({
        alerts: resp.data.alerts,
      });
    }).catch(this.handleError);
  }

  dismiss(index) {

  }

  handleError(e) {
    if (e.response?.status === 403) {
      this.props.router.navigate("/logout");
    } else {
      console.log(e);
    }
  }

  render() {
    return this.state.alerts.map((alert, index) => (
      <Alert key={alert.id} variant={alert.variant} onClose={() => this.dismiss(index)} dismissible={alert.dismissible}>
        {alert.heading && (
          <Alert.Heading>{alert.heading}</Alert.Heading>
        )}
        <p>
          {alert.text} {alert.linkUrl && (
            <Alert.Link href={alert.linkUrl}>{alert.linkText}</Alert.Link>
          )}
        </p>
      </Alert>
    ));
  }
}

export default Alerts;