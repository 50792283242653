import http from "../http-common";

class AlertService {
  getAlerts() {
    var token = window.sessionStorage.getItem("token");
    return http.get("/alerts", {params: {token: token}});
  }
}

export default new AlertService();
